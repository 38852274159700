<template>
  <div class="dataSet">
    <div class="buttonBox">
      <el-button size="small" type="primary" @click="addclick">新建</el-button>
      <el-button size="small" type="danger" @click="selectDelete">删除</el-button>
    </div>
    <div class="tableBox">
      <el-table ref="tableData" border :data="tableData" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" row-key="id" 
				style="width: 100%" height="100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
        <el-table-column prop="showOrder" label="顺序" align="center"></el-table-column>
        <el-table-column prop="dsName" label="数据集名称" align="center"></el-table-column>
        <el-table-column prop="menuType" label="菜单类型" align="center">
          <template slot-scope="scope">
            {{( $map.menuTypeDist[scope.row.menuType || 'default'])|| scope.row.menuType }}
          </template>
        </el-table-column>

        <el-table-column prop="referCondition" label="外键" align="center"></el-table-column>
        <el-table-column prop="connName" label="数据库连接" align="center"></el-table-column>
        <el-table-column prop="dsType" label="数据集类型" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="setclick(scope.row)" type="text" icon="el-icon-edit"></el-button>
            <el-popover placement="bottom" width="200" :ref="'popover-' + scope.row.id" trigger="click">
              <div class="delTip">
                <i class="el-icon-warning" style="color:#E6A23C"></i>确定删除吗？
              </div>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" @click="pCancel(scope.row.id)">取消</el-button>
                <el-button type="primary" size="mini" @click="deleteDeviceRow(scope.row)">确定</el-button>
              </div>
              <el-button style="color: red;" slot="reference" type="text" icon="el-icon-delete"></el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="page"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getdataSetlistPage, deletedataSetlist } from "@/api/dataSet/index.js";
export default {
  name: "dataSet",
  data() {
    return {
      tableData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      deletelist: [],
      ids: [],
   
    };
  },
  mounted() {
    this.init();

      },


	methods: {
		// 初始化数据
		init() {
      let obj = { pageNum: this.page, pageSize: this.pageSize, queryKey: "" };
      // obj.pageNum = this.page;
      // obj.pageSize = this.pageSize;
      getdataSetlistPage(obj).then((res) => {
        this.total = res.totalRecord;
        this.tableData = res.result;
   

      });
		},

		// 新建按钮
    addclick() {
      this.$router.push({ name: "setDataSet" });
		},

		// 复选框多选数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
		},

		// 多选删除按钮
    selectDelete() {
      if (this.ids.length >= 1) {
        this.$confirm(" 是否继续删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deletedataSetlist(this.ids).then((res) => {
            this.$message({ message: "删除成功", type: "success" });
            this.init();
          });
        });
      }
		},

		// 行编辑按钮
    setclick(row) {
      this.$router.push({ name: "addDataSet", params: row });
		},

   
		// 行删除确认按钮
    deleteDeviceRow(row) {
      this.deletelist = [];
      this.pClose(row.id);
      this.deletelist.push(row.id);
      deletedataSetlist(this.deletelist).then((res) => {
        this.$message({ message: "删除成功", type: "success" });
        this.init();
      });
		},

		// 行删除取消按钮
    pCancel(id) {
      this.pClose(id);
		},
    pClose(id) {
      this.$refs[`popover-` + id].doClose();
		},

		// 分页器方法
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
		},
		// 分页器方法
    handleCurrentChange(val) {
      this.page = val;
      this.init();
		},
  },
  beforeRouteEnter (to, from, next) {
      next(vm=>{
    
          setTimeout(() => {
            vm.init()
           
          });
          setTimeout(() => {
          vm.$refs.tableData?.doLayout();
        });
      })
      // ...
    },
};
</script>
<style scoped lang="less">
.dataSet {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  position: relative;
}
.buttonBox {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.tableBox {
  height: ceil(90% - 10px);
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}
.el-pagination {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
</style>
